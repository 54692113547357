import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { Toaster } from "react-hot-toast";
import { Header } from "../sections/homepage/header";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const DesignsPage: React.FC<PageProps> = ({ data }) => {
  // @ts-ignore
  const designNodes = data?.allAirtable.nodes
  const fileNodes = data?.allFile.nodes
  // console.log("----------------------------------")
  // console.log ("START All Designs Data:")
  // console.log("----------------------------------")
  // console.log(designNodes)
  //
  // console.log("----------------------------------")
  // console.log ("END All Designs Data:")
  // console.log("----------------------------------")
  //
  // console.log(fileNodes)
  // border-2 border-black
  return (
    <main className="pb-16">
      <Toaster></Toaster>
      <Header></Header>
      <div className="container">
        <h1 className="mt-16">Missing middle housing designs</h1>
        <div className="grid grid-cols-1 md:grid-cols-8 gap-12 mb-8">
          <div className="col-span-4">

            <p>Explore these designs for missing-middle housing, designed to take advantage of Builders Remedy.</p>

          </div>
          <div className="col-span-4 border-2 p-5">
            <h3 className="mb-0">Design parameters:</h3>
            <h4 className="mb-0">&mdash; <span
              className="font-bold text-pink_accent">5 unit</span> buildings</h4>
            <h4 className="mb-0">&mdash; <span
              className="font-bold text-pink_accent">50'x100'</span> residential lots</h4>
            <h4 className=" mb-0">&mdash; <span
              className="font-bold text-pink_accent">Energy-Efficient</span> Passive House designs</h4>

          </div>
        </div>

        <div className="my-8 border-t-2 w-full h-1"></div>

        <div className="grid grid-cols-1 gap-8">
          {designNodes?.map((design: any, idx: number) => {
            // const url = design.data.board.local?.url
            // console.log(design.designPath)
            const jpgNodeId = design.data.board.localFiles[0].fields?.jpgNodeId
            const imgNode = fileNodes.filter((node: any) => {
              return node.id === jpgNodeId
            })[0]
            // console.log ("imgNode = ", imgNode)
            const img = getImage(imgNode)
            const imgRender = img
              ? <GatsbyImage className="border-2 w-full md:w-[512px]" image={img} alt={design.data.Title_your_entry}/>
              : <div className="w-full md:w-[512px]">NO IMAGE</div>

            // const imgPath = design.data.board.localFiles[0].fields.jpg1024Path
            // console.log ("path", imgPath)

            return (
              <Link className="no-underline" key={idx} to={design.designPath}>
                <div className="p-4 flex flex-col md:flex-row justify-center gap-4 md:gap-8">
                  <div className="flex flex-col py-2 text-right content-end md:border-r-2 pr-6 w-full md:w-[512px]">
                    <h2 className="mb-4">{design.data.Title_your_entry}</h2>
                    <p className="mb-0 font-bold">by {design.data.Your_name}</p>
                    <p className="mb-0">{design.data.Company_name}</p>
                  </div>
                  {imgRender}
                  {/*<StaticImage src={imgPath} alt={design.data.Title_your_entry} />*/}
                  {/*{url && <img className="border-2 w-512px h-341px" alt={design.data.Title_your_entry}*/}
                  {/*             src={url}/>}*/}
                </div>
              </Link>
            )
          })
          }
        </div>
      </div>
    </main>
  )
}

export default DesignsPage

export const query = graphql`
    query Q {
        allAirtable {
            nodes {
                designPath: gatsbyPath(filePath: "/design/{Airtable.data__Title_your_entry}")
                data {
                    Title_your_entry
                    Your_name
                    board {
                        localFiles {
                            fields {
                                jpgNodeId
                            }
                        }
                    }
                    Company_name
                }
            }
        }
        allFile(filter: { name: { glob: "jpg-*" } } ) {
            nodes {
                id
                name
                internal {
                    description
                    ignoreType
                    mediaType
                    contentFilePath
                }
                childImageSharp {
                    gatsbyImageData(
                        width: 512
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
#                childImageSharp {
#                    fluid(maxWidth:512) {
#                        ...GatsbyImageSharpFluid
#                    }
#                    gatsbyImageData(
#                        placeholder: BLURRED
#                        formats: [AUTO, WEBP, AVIF]
#                    )
#                }
            }
        }

    }
`
